require('../../node_modules/fancybox/dist/css/jquery.fancybox.css');

(function($) {
    xhr = $.ajax({});
})(jQuery);

function convert(nombre, nbChiffres)
{
    return Math.round(parseFloat(nombre) * Math.pow(10, 2)) / Math.pow(10, 2);
}

isFloat = function(number) {
   try {
     return (number.toString().indexOf(".") != -1);
   } catch (ex) { return false; }
}

function replace(nb)
{
    x = nb.toString();
        if(isFloat(x)>0){
        x = parseFloat(nb.toString());
        x= x.toFixed(2);
    }
    x = x.replace('.', ',');
    return x;
}

function minimumInitialAmount(maValue,cible,pourcentage){
    // je récupère la value de mon id
    // je calcule les x% de ma value
    var result = (maValue*pourcentage)/100;
    // je cible la tooltip où je dois afficher la value (=cible)
    // je remplace le text du span dans la cible
    $(cible).find('span').text(result);
}

function kitchenDisabledButton(){
            var result = $('.kitchen-tooltip.bottom').find('span').text();
            var myCompare = $('#itroom_boulangersimulationbundle_financement_apportInitial').val();

            $('#financement input[type=submit],#recap input[type=submit]').removeAttr('disabled').removeClass('disabled');

            if ( parseInt(myCompare) < parseInt(result) ) {
                $('.kitchen-tooltip.bottom').show();
                $('#financement input[type=submit],#recap input[type=submit]').attr('disabled','disabled').addClass('disabled');
            } else {
                $('.kitchen-tooltip.bottom').hide();
                $('#financement input[type=submit],#recap input[type=submit]').removeAttr('disabled').removeClass('disabled');
            }
}

function rondDisabledButton(){
            var myCompare = $('#itroom_boulangersimulationbundle_financement_apportInitial').val();

            $('#financement input[type=submit],#recap input[type=submit]').removeAttr('disabled').removeClass('disabled');

            $('.rond-tooltip').show();
            if ( parseInt(myCompare) >0 ) {
                $('#financement input[type=submit],#recap input[type=submit]').attr('disabled','disabled').addClass('disabled');
                $('.rond-tooltip.bottom').show();
            } else {
                $('#financement input[type=submit],#recap input[type=submit]').removeAttr('disabled').removeClass('disabled');
                $('.rond-tooltip.bottom').hide();
            }
}

$(document).ready(function() {

    /**
     * Style boutons radios
     */
     if ($('.type-text.radios').length > 0) {
        $('.type-text.radios .radios').hide();
        $('.type-text.radios').each(function(e, i) {
            var rootElement = $(this);
            var conteneurRadios = $(this).find('.radios');
            conteneurRadios.before('<div class="fake-radios"></div>');  

            conteneurRadios.find('input').each(function() {
                var actif = "";

                if ($(this).attr('required') == "required") {
                    actif += " required";
                }

                if ($(this).prop("checked")) {
                    actif += " check";
                }
                var label = $(this).next('label').text();
                rootElement.find('.fake-radios').data('dom', {'model': conteneurRadios});
                rootElement.find('.fake-radios').append('<div class="bouton-radio' + actif + '">' + label + '</div>')
            });
        });

        $('form').on('click', '.bouton-radio', function() {
            var value = $(this).text();
            var parent = $(this).parent();
            var rootElement = parent.data('dom').model;

            if ($(this).hasClass('check') && $(this).hasClass('required')) {
                return false;
            }

            if ($(this).hasClass('check')) {
                parent.find('.bouton-radio').removeClass('check');
                rootElement.find('input').prop("checked", false);
            }
            else {
                parent.find('.bouton-radio').removeClass('check');
                $(this).addClass('check');
                rootElement.find('input[value=' + value + ']').prop("checked", true);
            }
        });
    }

    /**
     * Style boutons checkbox
     */
    if ($('.type-text.checkboxes').length > 0) {

        $('.type-text.checkboxes input[type=radio]').hide().each(function(e, i) {
            var check = "";
            if ($(this).prop('checked')) {
                check = " check";
            }
            $(this).before('<div class="checkbox' + check + '"></div>');
        });

        $('body').on('click', '.checkbox', function() {
            var name = $(this).next('input').attr('name');

            $('input[name="' + name + '"]').prev('.checkbox').removeClass('check');
            $(this).addClass('check');
            $(this).next('input[type=radio]').prop('checked', true);

            // affichage de .kitchent-tooltip uniquement si on a cliqué sur Cuisine
            var nextId = $(this).next('input').attr('id');
            $('.kitchen-tooltip').hide();
            $('.rond-tooltip').hide();
            if (
                (nextId === 'itroom_boulangersimulationbundle_financement_OptionsFinancement_3')
            || (nextId === 'itroom_boulangersimulationbundle_financement_OptionsFinancement_7')
            ) {
                minimumInitialAmount($('#itroom_boulangersimulationbundle_financement_montant').val(), '.kitchen-tooltip', 10);
                $('.kitchen-tooltip.top').show();
                if ($('.itroom_boulanger_simulation_front_solution #financement').is(':visible')) {
                    $('.kitchen-tooltip.top').show();
                }
                kitchenDisabledButton();
                afficherGarantie(false);
            } else {
                afficherGarantie(true);
                $('#financement input[type=submit],#recap input[type=submit]').removeAttr('disabled').removeClass('disabled');
                $('.kitchen-tooltip').hide();
            }

        });

        $('body').on('click', '.checkbox + input + label', function() {
            $(this).prev('input').prev('.checkbox').trigger('click');
            $('#itroom_boulangersimulationbundle_financement_apportInitial').trigger('keyup');
        });
    }

    /* trigger change sur apport au chargement */
    $('#itroom_boulangersimulationbundle_financement_apportInitial').trigger('keyup');

    $('.form-reparation input, .form-remboursement input[type=text]').on({
            keyup: function(){
                if($(this).val() != ''){
                    if($(this).parents('fieldset').find('.bouton-radio.check').length == 0){
                        $(this).parents('fieldset').find('.bouton-radio:first-child').trigger('click');
                        $(this).parents('fieldset').find('.radio-container:first-child input').prop('checked', true)
                    }
                }
                else{
                    $(this).parents('fieldset').find('.bouton-radio.check').trigger('click');
                }
            },
            change:function(){
                if($(this).val() == '0'){
                    $(this).val('');
                    $(this).trigger('keyup');
                }
            }
    });

    $('#financement input[type=text]').on('keyup', function(){
        var saisie = $(this).val().replace(',', '.');

        if(isNaN(saisie)){
            if(isNaN(parseFloat(saisie))){
                $(this).val('');
            }
            else{
                $(this).val(parseFloat(saisie));
                $(this).val($(this).val().replace('.', ','));
            }
        }
    });

    /**
     * edition formulaire sur page solutions
     */

    $('.modifier').on('click', function() {
      minimumInitialAmount($('#itroom_boulangersimulationbundle_financement_montant').val(), '.kitchen-tooltip', 10);

        var idParent = $(this).parent().attr('id');
        if (idParent == 'recap' && $('#itroom_boulangersimulationbundle_financement_OptionsFinancement_3').prev().hasClass('check') || idParent == 'recap' && $('#itroom_boulangersimulationbundle_financement_OptionsFinancement_7').prev().hasClass('check') ) {
            $('body').addClass('activ-'+idParent);
        }

        if ($('#financement').is(':visible')) {
            $('#financement').slideUp();
            $('.kitchen-tooltip').hide();
            $('.rond-tooltip').hide();
        }
        else {
            $('#financement').slideDown();

            if (
                (
                    $('#itroom_boulangersimulationbundle_financement_OptionsFinancement_3').prev().hasClass('check')
                || $('#itroom_boulangersimulationbundle_financement_OptionsFinancement_7').prev().hasClass('check')
                )
                && $('.form-achat input#itroom_boulangersimulationbundle_financement_apportInitial[type=text]').is(':visible')
            ) {
               $('.kitchen-tooltip').show();
               afficherGarantie(false);
            }
        }
    });

    $('body').on('keyup', '#reference, #itroom_boulangersimulationbundle_offrefinancement_reference', function(){
        $(this).val($(this).val().toUpperCase());
        var myString=$(this).val();
        if(myString.substring(0, 1) != 'F'){
            $(this).val('F'+$(this).val());
        }
    });

    $('body').on('click', '.garanties-check', function() {
      if (this.classList.contains('ceg-garanties')) {
        $('#itroom_boulangersimulationbundle_financement_infinity input').prop('checked', false)
      } else if (this.classList.contains('infinity-garanties')) {
        $('.CEG-block input[type=radio]').prop('checked', false)
        $('.CEG-block input[type=text]').val('')
      }
    })

    /**
     * popins
     */

    $('#cms_pop_promo, #cms_pop_espace_carte_b_plus').fancybox({
        'type':'iframe',
        'height':"100%",
        'width':1100
    });

    $('#cms_pop_avantage_carte_b_plus').fancybox({
        'type':'iframe',
        'height':"100%",
        'width':700
    });

    $('#cms_pop_calendrier').fancybox({
        'type':'iframe',
        'height':"100%",
        'width':1430
    });

    $('.details').on('click', function(){
        var infos=$(this).parent().find('.popin').html()
        $.fancybox({
            'content':infos,
            'autoDimensions':false,
            'height':500,
            'width':700
        });
    });

    if($('.alert-error').length>0){
        var infos=$('.alert-error').clone();
        $.fancybox({
            'content':infos
        });
    }

    /**
     * fin popins
     */


    /**
     * Calcul chèque économie
     */
    function getValues() {
        var montant = (isNaN(parseFloat($('input[id$="_montant"]').val()))) ? 0 : parseFloat($('input[id$="_montant"]').val());
        var services = (isNaN(parseFloat($('input[id$="_montantServices"]').val()))) ? 0 : parseFloat($('input[id$="_montantServices"]').val());
        var reparation = (isNaN(parseFloat($('input[id$="_montantReparation"]').val()))) ? 0 : parseFloat($('input[id$="_montantReparation"]').val());
        var remboursement = (isNaN(parseFloat($('input[id$="_montantRemboursement"]').val()))) ? 0 : parseFloat($('input[id$="_montantRemboursement"]').val());

        return { montant, services, reparation, remboursement };
    }

// Gestionnaire d'événement pour tous les champs
    $('.form-achat input[type=text], .form-reparation input[type=text], .form-remboursement input[type=text]').on('keyup', function() {
        var { montant, services, reparation, remboursement } = getValues();

        cumulEconomies(montant, services, reparation, remboursement, $('#economies .cheque span'));

        if (
            ($('#itroom_boulangersimulationbundle_financement_OptionsFinancement_3').prev().hasClass('check')
                || $('#itroom_boulangersimulationbundle_financement_OptionsFinancement_7').prev().hasClass('check'))
            && $('.form-achat input#itroom_boulangersimulationbundle_financement_apportInitial[type=text]').is(':visible')
        ) {
            // test sur la valeur de l'apport initial
            // pour alimenter la tooltip
            minimumInitialAmount(montant, '.kitchen-tooltip', 10);
            // pour désactiver le bouton
            kitchenDisabledButton();
        };
    });

    function cumulEconomies(montant, montantService, reparation, remboursement, cible) {
        var total = (montant - montantService) * 2 + (reparation * 2) + (remboursement * 2);
        cible.html(total + ' points');
    }



    function montantFinancement(){
        var montant = (isNaN(parseFloat($('input[id$="_montant"]').val()))) ? 0 : parseFloat($('input[id$="_montant"]').val());
        var services = (isNaN(parseFloat($('input[id$="_montantServices"]').val()))) ? 0 : parseFloat($('input[id$="_montantServices"]').val());
        var apport = (isNaN(parseFloat($('input[id$="_apportInitial"]').val()))) ? 0 : parseFloat($('input[id$="_apportInitial"]').val());

        var montantTotal = montant + services - apport;

        return montantTotal;
    }



    /**
     * Vérifier si montant est correcte pour l'option choisie
     */
    $('#financement').on('submit', function(e){
        var optionFinancement = $('.options-financement input[type=radio]:checked').attr('value');
        var valide=false;

        const opcCode = $('#itroom_boulangersimulationbundle_financement_opcs input[type=radio]:checked').attr('value');

        $.ajax({
            url:urlVerifMontant,
            type:'POST',
            data:"montant="+montantFinancement()+"&OptionsFinancementId="+optionFinancement+'&code='+opcCode,
             success:function(result){
                 if($('#financement').validate().form()){
                     if(result=='true'){
                         valide=true;
                     }
                     else{
                         $.fancybox(
                             '<div class="alert-error">Le montant à financer ne rentre pas dans le cadre du financement choisi : il doit être entre '+result+'.</div>'
                         );
                     }
                 }
                 if(valide==true){
                     $('body').addClass('loading');
                     if($('.modifier').length>0 && $('#financement:visible').length>0){
                        $('.modifier').trigger('click');
                     }
                     $('#contenu .centre').height($('#contenu .centre').height());
                     $('#contenu .centre *').hide();
                     $('#financement').off('submit').submit();
                 }
            }
        });
        e.preventDefault();
    });

    $(document).on('change', '#fancybox-content input', function (e) {
        updateForm($(this));
    });

    function updateForm(input) {
        if(input.attr('name') != 'itroom_boulangersimulationbundle_offrefinancement[piecesJustificatives][]') {
            var valReference = $('#fancybox-content input[name="itroom_boulangersimulationbundle_offrefinancement[reference]"]').val();
            var valClient = $('#fancybox-content input[name="itroom_boulangersimulationbundle_offrefinancement[client]"]').val();
            var valMatricule = $('#fancybox-content input[name="itroom_boulangersimulationbundle_offrefinancement[matricule]"]').val();

            if((valReference != '') && (valClient != '') && (valMatricule != '')) {
                var form = input.closest("form");
                $.ajax({
                    type: "post",
                    data: form.serialize(),
                    dataType: "json",
                    url: '/front/offreFinancement',
                    success: function (json) {
                        form.parent().html(json.html);
                        var hiddenFields = $('.hidden-fields-origin').html();
                        $(".hidden-fields").html(hiddenFields);
                    },
                    error: function (response, status, errorMessage) {
                        alert(errorMessage);
                    }
                });
            }
        }
    }

    let allRadioBtnExceptFirst = document.querySelectorAll('.bouton-radio:not(:first-child)');

    

    allRadioBtnExceptFirst.forEach(element => {
        if(element.classList.contains('check')){
            $('.bouton-radio:first-child').removeClass('check');
        }
    });

    $('.bouton-radio:first-child').attr('checked', true);
});

function afficherGarantie(afficher) {
    if (afficher) {
        $('.form-reparation').show();
        $('.form-remboursement').show();
        $('.form-reparation').prev().show();
    } else {
        $('.form-reparation').prev().hide();
        $('.form-reparation').hide();
        $('.form-remboursement').hide();
    }
}

function updateFields(offre) {

    var duree = offre.find('.duree').html();
    var dureetotal = duree;

    if($('.financement6').length > 0) {
        dureetotal = parseFloat(duree)+1;
    }

    $('.offre .valider').val('Selectionner cette offre');

    if(offre.hasClass('pave-reparation')) {
        $('#validation #id_reparation').val(1);
        $('#validation #id_remboursement').val(0);
    }
    if(offre.hasClass('pave-remboursement')) {
        $('#validation #id_reparation').val(0);
        $('#validation #id_remboursement').val(1);
    }
    if(offre.hasClass('pave-achat')) {
        $('#validation #id_reparation').val(0);
        $('#validation #id_remboursement').val(0);
    }

    $('.offre').removeClass('select');
    offre.find('.valider').val('Transmettre les informations en caisse');
    offre.addClass('select');
}